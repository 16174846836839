import { Divider, Link, PaperCutLogo, Stack, Typography } from '@papercutsoftware/pcds-react'
import { config } from '@/utils/config'

const ProductSelector = () => {
  const loginUrl = config().loginUrl

  return (
    <Stack direction="column" spacing={4}>
      <Typography variant="h2" component="h2">
        {'Log in to'}
      </Typography>
      <Stack direction="row" spacing={3}>
        <Link href={loginUrl.pocket} target="_self">
          <PaperCutLogo name="pocket" size="xl" />
        </Link>
        <Divider orientation="vertical" />
        <Link href={loginUrl.hive} target="_self">
          <PaperCutLogo name="hive" size="xl" />
        </Link>
      </Stack>
    </Stack>
  )
}

export default ProductSelector
