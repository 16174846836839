'use client'

import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { Loader, OnboardingTemplate, Stack, SubmitButton, TextField } from '@papercutsoftware/pcds-react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { isValidEmail } from '@/utils/emailValidation'
import { getTenantsByEmailAddress, getTenantsByExternalEntity, Tenant } from '@/api/tenants'
import TenantSelector from '@/components/TenantSelector/TenantSelector'
import TenantLogin from '@/components/TenantLogin/TenantLogin'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'
import LoginSignUpPageHeading from '@/components/LoginSignUpPageHeading/LoginSignUpPageHeading'
import { toMessage } from '@/utils/errorCodes'
import { ProductContext, unsetProductId } from '@/context/product'
import { errorQueryParam, tenantIDQueryParam } from '@/utils/pageurl/pageurl'
import { renderError } from '@/components/RenderAlert/RenderErrorAlert'
import ProductSelector from '@/components/ProductSelector/ProductSelector'

type FormState = {
  email: string
}

const Home: FC = () => {
  const [formState, setFormState] = useState<FormState>({ email: '' })
  const [tenantsToSelect, setTenantsToSelect] = useState<Tenant[]>([])
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPageInitialized, setIsPageInitialized] = useState<boolean>(true)
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null)

  const router = useRouter()
  const searchParams = useSearchParams()
  const urlErrorMsg = searchParams.get(errorQueryParam) ? toMessage(searchParams.get(errorQueryParam)!) : null
  const tenantId = searchParams.get(tenantIDQueryParam)
  const externalEntityId = searchParams.get('externalEntityId')
  const dataCenter = searchParams.get('dataCenter')
  const product = useContext(ProductContext)
  const productId = product.getId()

  useEffect(() => {
    if (!tenantId && externalEntityId && dataCenter) {
      const fetchTenantByExternalEntity = async () => {
        setIsPageInitialized(false)
        try {
          const response = await getTenantsByExternalEntity(
            externalEntityId as string,
            dataCenter as string,
            product.getId(),
          )
          if (response?.tenants.length) {
            setSelectedTenant(response.tenants[0].id)
          } else {
            console.error('fetchTenantByExternalEntity: no tenant found')
          }
        } catch (error) {
          console.error('fetchTenantByExternalEntity', error)
        } finally {
          setIsPageInitialized(true)
        }
      }
      fetchTenantByExternalEntity()
    } else {
      setSelectedTenant(tenantId)
    }
  }, [tenantId, externalEntityId, dataCenter])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!formState?.email?.trim()) {
      setError('Email address is required.')

      return
    }

    if (router.query.error) {
      const { pathname, query } = router
      delete router.query.error
      router.replace({ pathname, query }, undefined, { shallow: true })
    }

    if (isValidEmail(formState.email)) {
      setIsLoading(true)
      try {
        const res = await getTenantsByEmailAddress(formState.email, productId!)
        const tenants = res?.tenants
        if (tenants?.length) {
          setError(null)

          if (tenants.length == 1) {
            setSelectedTenant(tenants[0].id)
            router.push({
              query: { ...router.query, tenantId: tenants[0].id, prefilledEmail: formState.email },
            })
          } else {
            setTenantsToSelect(tenants!)
          }
        } else {
          setError(
            `Please check you have entered your email address correctly and your organization uses ${product.getName()}`,
          )
        }
      } catch (error) {
        console.error('Error fetching tenants:', error)
        setError('Error fetching tenants.')
      } finally {
        setIsLoading(false)
      }
    } else {
      setError('Enter a valid email address.')
    }
  }

  const renderForm = () => {
    if (router.isReady && productId === unsetProductId) {
      return <ProductSelector />
    }

    if (!isPageInitialized) {
      return <Loader />
    }
    if (selectedTenant) {
      return <TenantLogin tenantId={selectedTenant} />
    }

    if (!tenantsToSelect || tenantsToSelect.length === 0) {
      return (
        <StyledFormWrapper direction="column" spacing={0}>
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={2}>
              <LoginSignUpPageHeading />
              <TextField
                label="Email"
                id="email-field"
                placeholder="Enter your email"
                defaultValue={formState.email}
                error={Boolean(error)}
                errorMessage={error || undefined}
                autoComplete="on"
                onChange={(event) => {
                  const newValue = event.target.value
                  if (newValue !== null && newValue !== undefined) {
                    setFormState((prevState) => ({
                      ...prevState,
                      email: newValue.trim(),
                    }))
                  }
                }}
              />
              {urlErrorMsg ? renderError(urlErrorMsg) : <></>}
              <SubmitButton label="Continue" loading={isLoading} />
            </Stack>
          </form>
        </StyledFormWrapper>
      )
    } else {
      return (
        <TenantSelector
          tenants={tenantsToSelect}
          onChangeEmailAddressClicked={() => {
            setTenantsToSelect([])
            setFormState({ email: '' })
          }}
          userEmailAddress={formState.email}
        />
      )
    }
  }

  if (!router.isReady) {
    return
  }

  return (
    <OnboardingTemplate termsOfServiceUrl={product.getTermsOfServiceUrl()} product={productId} topRightContent={<></>}>
      {renderForm()}
    </OnboardingTemplate>
  )
}

export default Home
